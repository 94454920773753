<!-- 新冠特异性IgM/IgG抗体 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">
                <span style="width: 225px;color: #FFFFFF;">新冠特异性IgG抗体</span>
            </div>
            <div class="content">
                <div style="display:flex;">
                    <div class="tip">检测内容</div>
                    <div class="value">IgG</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">检测结果<br />(OD值)</div>
                    <div class="value">0.8</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">判断结果</div>
                    <div class="value">阳性</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">参考范围</div>
                    <div class="value">OD值＞0.15（阳性）<br />OD值≤0.15（阴性）</div>
                </div>
            </div>
        </div>


        <div class="globalTitle" style="margin:30px 0 12px 0;color: #926BFF;">指标解释</div>
        <div class="globalText">
            <p style="margin-bottom: 14px;">
                新冠抗体是人体感染新型冠状病毒（SARS-CoV-2）或接种疫苗后，免疫系统做出免疫防御，产生的对抗感染的特异性抗体，这类特异性抗体包括IgM抗体和IgG抗体。
            </p>
            通常IgM和IgG抗体在感染的1-2周内出现（IgM抗体在感染后5-7天出现，而IgG抗体在10-14天出现），IgM抗体是较早出现的抗体，IgG抗体晚于IgM抗体。IgM抗体持续1-2周就会消失，而IgG抗体则持续更长的时间。有研究表明在感染新型冠状病毒后，IgG抗体可持续存在半年以上。IgG呈阳性而IgM为阴性，通常表示是痊愈者，不仅不具有传染性，而且还可以抵抗病毒的感染。
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
@import url(../../../static/css/global.css);

.top {
    width: 100%;
    height: 40px;
    background: #926BFF;
    border-radius: 8px 8px 0px 0px;
    padding: 9px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    width: 100%;

    .tip {
        width: 42%;
        background: #FAFCFF;
        padding: 9px 0 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        border-right: none;
        margin-top: -1px;
        display: flex;
        align-items: center;
    }

    .value {
        // width: 191px;
        flex: 1;
        background: #FAFCFF;
        padding: 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        margin-top: -1px;
        display: flex;
        align-items: center;
    }
}
</style>
